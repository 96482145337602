import React, { useState, useEffect, useMemo } from 'react';
import { FileMap } from '../pages/PromptSandbox/types';

const TOKEN_LIMIT = 128000;

export const useTokenLimit = ({ fileMap }: { fileMap: FileMap }) => {
  const [hasAcknowledgedLimitModal, setHasAcknowledgedLimitModal] = useState<boolean>(false);

  const totalTokens = useMemo<number>(() => {
    return Object.values(fileMap).reduce((acc, fileMeta) => acc + (fileMeta?.estimatedTokens ?? 0), 0);
  }, [fileMap]);

  useEffect(() => {
    if (totalTokens < TOKEN_LIMIT) setHasAcknowledgedLimitModal(false);
  }, [totalTokens]);

  return {
    hideTokenLimitModal: () => setHasAcknowledgedLimitModal(true),
    showTokenLimitModal: totalTokens >= TOKEN_LIMIT && !hasAcknowledgedLimitModal,
  };
};
