import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, IconButton, Button, Typography } from '@mui/material';
import { Uploads, DocFetchParams, FileMap } from './types';
import { getModels, getLlmResponseStreaming } from '../../interactors/prompt-sandbox';
import { uploadFileSync } from '../../interactors/file';
import EmptyContent from './EmptyContent';
import { LLMModel, LlmTranscript } from '../../types/promptSandbox';
import { NotificationContext } from '../../App';
import { safeTrack } from '../../provider/Pendo';
import { useTokenLimit } from '../../hooks';
import AttachFile from '@mui/icons-material/AttachFile';
import { useDropzone } from 'react-dropzone';
import { calculateChecksum256 } from '../../components/DocResolver/utils';
import { OCRApiDocument } from '../../types/ocr_document';
import { DocResolver } from '../../components/DocResolver';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fetchDocument, getDocumentTokenEstimate } from './utils';

import { Message } from './Message';
import { LimitExceededModal } from './LimitExceededModal';

import { ERROR_MESSAGES, mapPromptApiErrorCodeToMessage, UPLOAD_FILE_ERROR_TO_MESSAGE } from './messages';

export default function Chat() {
  const [searchParams] = useSearchParams();
  const [transcript, setTranscript] = useState<LlmTranscript>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [modelId, setModelId] = useState<string>('gpt-4o-mini');
  const [isLoadingAiAnswer, setIsLoadingAiAnswer] = useState<boolean>(false);
  const [isUploadingDocumentById, setIsUploadingDocumentById] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [availableModels, setAvailableModels] = useState<LLMModel[]>([]);
  const [uploads, setUploads] = useState<Uploads>({});
  const [fileMap, setFileMap] = useState<FileMap>({});
  const [uploadedDocument, setUploadedDocument] = useState<{ name: string; size: number; state: string } | null>(null);
  const [streamingAiMessage, setStreamingAiMessage] = useState<string | null>(null);
  const { showTokenLimitModal, hideTokenLimitModal } = useTokenLimit({ fileMap });

  const { setToastMessage } = useContext(NotificationContext);

  const anyDocumentsBeingUploaded = isUploadingDocumentById || !!uploadedDocument;

  useEffect(() => {
    getModels()
      .then(setAvailableModels)
      .catch((error) => {
        setToastMessage({ message: JSON.stringify(error), severity: 'error' });
      });
  }, []);

  const handleAddMessageToTranscript = (role: string, content: string, id?: string) => {
    setTranscript((prevItems) => [...prevItems, { role, content, id }]);
  };

  const handleAddDocument = (documentId: string, documentName: string) => {
    setDocumentIds((prevItems) => {
      const newDocumentIds = [...new Set([...prevItems, documentId])];
      if (newDocumentIds.length !== prevItems.length) {
        handleAddMessageToTranscript('document', documentId);
      }
      return newDocumentIds;
    });
    handleSetFileMap(documentId, documentName);
  };

  const setTokenEstimate = useCallback(async (docId: string) => {
    const tokenEstimate = await getDocumentTokenEstimate(docId);
    setFileMap((prevMap) => {
      if (!prevMap[docId]) return prevMap;
      return { ...prevMap, [docId]: { ...prevMap[docId], estimatedTokens: tokenEstimate } };
    });
  }, []);

  const handleSetFileMap = async (docId: string, docName: string) => {
    setFileMap((prevMap) => ({ ...prevMap, [docId]: { name: docName } }));
    setTokenEstimate(docId); // let this happen async, could take a while
  };

  const handleLlmResponse = useCallback(
    async (userMessage: string) => {
      setIsLoadingAiAnswer(true);
      const filteredTranscript = [...transcript.filter(({ role }) => ['user', 'assistant'].includes(role)), { role: 'user', content: userMessage }].map(({ role, content }) => ({ role, content }));
      const transcriptWithoutErrors = filterConsecutiveUserMessages(filteredTranscript);
      const shoudlUseInputText = !!inputText.length;
      try {
        const getResponseParams = {
          model_id: modelId,
          transcript: transcriptWithoutErrors,
          document_ids: shoudlUseInputText ? undefined : documentIds,
          input_text: shoudlUseInputText ? inputText : undefined,
        };

        let totalText = '';
        let messageId;
        for await (const chunk of getLlmResponseStreaming(getResponseParams)) {
          const { state } = chunk;

          if (state === 'STREAMING') {
            totalText += chunk.message;
            setStreamingAiMessage(totalText);
          } else if (state === 'DONE') {
            messageId = chunk.id;
            setStreamingAiMessage(null);
          }
        }

        handleAddMessageToTranscript('assistant', totalText, messageId);
      } catch (error: any) {
        handleAddMessageToTranscript('error', mapPromptApiErrorCodeToMessage(error?.code));
      }

      setIsLoadingAiAnswer(false);
    },
    [modelId, transcript, documentIds, inputText, transcript, setIsLoadingAiAnswer, handleAddMessageToTranscript]
  );

  const handleUserMessage = (message: string) => {
    handleAddMessageToTranscript('user', message);
    handleLlmResponse(message);
  };

  const handleDropFiles = async (drops: Blob[]) => {
    try {
      // TODO: currently dropzone only allows 1 document at the time, in the future we should support multiple
      const newUploads = { ...uploads };
      const drop = drops[0];
      const checksum = await calculateChecksum256(drop);
      const name = drop.name;
      const size = drop.size;
      if (newUploads[checksum]) {
        return;
      }

      setUploadedDocument({ name, size, state: 'UPLOADING' });
      newUploads[checksum] = true;
      setUploads(newUploads);

      for await (const chunk of uploadFileSync(drop)) {
        const status = chunk.status;

        if (!['ERROR', 'READY'].includes(status)) {
          setUploadedDocument((prev) => ({ ...prev, state: status }));
        } else if (status === 'READY') {
          const documentId = chunk.document_id;
          const versionId = chunk.version_id;
          safeTrack('File Upload', { documentId, versionId, name, size });
          setUploadedDocument(null);
          if (status === 'READY') {
            handleAddDocument(documentId, name);
          }
        } else if (status === 'ERROR') {
          const message = chunk.message;
          setUploadedDocument(null);
          handleAddMessageToTranscript('error', UPLOAD_FILE_ERROR_TO_MESSAGE[message] ?? message);
        }
      }
    } catch {
      setUploadedDocument(null);
      handleAddMessageToTranscript('error', ERROR_MESSAGES.DOCUMENT_UPLOAD_FAILURE);
    }
  };

  const handleFetchDocument = async (docFetchParams: DocFetchParams): Promise<void> => {
    try {
      setIsUploadingDocumentById(true);

      handleAddMessageToTranscript('loading_user', 'Loading document...');

      const document = await fetchDocument(docFetchParams);

      if (!document?.metadata) {
        throw new Error('No document metadata');
      }

      const documentId = document?.metadata?.id;

      if (documentIds.includes(documentId)) {
        setToastMessage({ message: ERROR_MESSAGES.DOCUMENT_ALREADY_ADDED, severity: 'error' });
        return;
      }

      setToastMessage({ message: 'Document added', severity: 'success' });
      handleAddDocument(documentId, document.metadata.name);
    } catch (e: any) {
      const message = e.status === 404 ? ERROR_MESSAGES.DOCUMENT_NOT_FOUND : 'An error occurred';
      setToastMessage({ severity: 'warning', message });
      throw e;
    } finally {
      setIsUploadingDocumentById(false);
      setTranscript((prevItems) => prevItems.filter((msg) => !['loading_assistant', 'loading_user'].includes(msg.role)));
    }
  };

  useEffect(() => {
    const documentIdQuery = searchParams.get('documentId') ?? '';
    if (documentIdQuery) {
      handleFetchDocument({ documentId: documentIdQuery });
    }
  }, []);

  const handleClearTranscript = () => {
    setDocumentIds([]);
    setTranscript([]);
  };

  const handleDeleteDocument = (documentId: string) => {
    const newTranscript = transcript.filter((item) => item.content !== documentId);
    const newDocumentIds = documentIds.filter((id) => id !== documentId);

    setTranscript(newTranscript);
    setDocumentIds(newDocumentIds);
    setFileMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[documentId];
      return newMap;
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height="calc(100vh - 64px)" padding={'32px'}>
      <LimitExceededModal open={showTokenLimitModal} onClose={hideTokenLimitModal} />
      <Settings setModel={setModelId} model={modelId} availableModels={availableModels} clearTranscript={handleClearTranscript} disableClear={!transcript.length} />
      <Transcript
        transcript={transcript}
        isLoading={isLoadingAiAnswer}
        uploadedDocument={uploadedDocument}
        fileMap={fileMap}
        anyDocumentsBeingUploaded={anyDocumentsBeingUploaded}
        deleteDocument={handleDeleteDocument}
        streamingAiMessage={streamingAiMessage}
      />
      <UserInput
        sendMessage={handleUserMessage}
        isChatbotResponding={isLoadingAiAnswer}
        isDocumentUploading={anyDocumentsBeingUploaded}
        isQuestionInputDisabled={!documentIds.length && !inputText.length}
        onFetchDocument={handleFetchDocument}
        inputText={inputText}
        setInputText={setInputText}
        uploadFiles={handleDropFiles}
      />
    </Box>
  );
}

function Transcript({
  streamingAiMessage,
  transcript,
  isLoading,
  uploadedDocument,
  fileMap,
  anyDocumentsBeingUploaded,
  deleteDocument,
}: {
  streamingAiMessage?: string;
  transcript: LlmTranscript;
  isLoading: boolean;
  resolveDocument?: (checksum: string, document: OCRApiDocument) => void;
  uploadedDocument: { name: string; size: number } | null;
  anyDocumentsBeingUploaded: boolean;
  fileMap: FileMap;
  deleteDocument: (documentId: string) => void;
}) {
  const transcriptRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom when a new message is added
  useEffect(() => {
    if (transcriptRef.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [transcript, anyDocumentsBeingUploaded]);

  if (!transcript.length && !anyDocumentsBeingUploaded) {
    return <EmptyContent />;
  }

  const adjustedTranscript = transcript.map((message) => {
    if (message.role !== 'document') {
      return message;
    }

    return { ...message, content: fileMap[message.content].name, documentId: message.content };
  });

  return (
    <Box
      ref={transcriptRef}
      height={'100%'}
      overflow="scroll"
      display="flex"
      flexDirection="column"
      padding="16px"
      sx={(theme) => ({ backgroundColor: theme.palette.background.secondary, borderRadius: '6px' })}
    >
      {adjustedTranscript.map(({ content, role, id, documentId }, key) => (
        <Message key={key} content={content} role={role} id={id} documentId={documentId} deleteDocument={deleteDocument} />
      ))}
      {uploadedDocument && (
        <Box display="flex" justifyContent={'flex-end'} marginBottom={'8px'}>
          <DocResolver name={uploadedDocument.name} size={uploadedDocument.size} state={uploadedDocument.state} />
        </Box>
      )}
      {isLoading && <Message content={'Loading...'} role={'loading_assistant'} />}
      {streamingAiMessage && <Message content={streamingAiMessage} role={'streaming'} />}
    </Box>
  );
}

function UserInput({
  sendMessage,
  isQuestionInputDisabled,
  isChatbotResponding,
  isDocumentUploading,
  onFetchDocument,
  uploadFiles,
}: {
  sendMessage: (message: string) => void;
  isChatbotResponding: boolean;
  isDocumentUploading: boolean;
  isQuestionInputDisabled: boolean;
  onFetchDocument: (searchParams: DocFetchParams) => Promise<void>;
  inputText: string;
  setInputText: (text: string) => void;
  uploadFiles: (blobs: Blob[]) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const { setToastMessage } = useContext(NotificationContext);

  const isLoading = isChatbotResponding || isDocumentUploading;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadFiles,
    accept: { 'application/pdf': ['.pdf'] },
    maxFiles: 1,
  });
  const onClick = getRootProps().onClick;
  const rootProps = {
    ...getRootProps(),
    onClick: () => {},
  };

  const handleMessageSubmit = useCallback(() => {
    if (isDocumentUploading) {
      return setToastMessage({
        severity: 'warning',
        message: ERROR_MESSAGES.CANNOT_SUBMIT_WHILE_DOCUMENT_IS_LOADING,
      });
    } else if (isChatbotResponding) {
      return setToastMessage({
        severity: 'warning',
        message: ERROR_MESSAGES.CANNOT_SUBMIT_WHILE_AI_IS_RESPONDING,
      });
    } else if (isQuestionInputDisabled && inputValue.length) {
      return setToastMessage({
        severity: 'warning',
        message: ERROR_MESSAGES.CANNOT_SUBMIT_WITH_NO_DOCS_SUPPLIED,
      });
    }

    sendMessage(inputValue);
    setInputValue('');
  }, [[inputValue, sendMessage, isQuestionInputDisabled, isLoading]]);

  const handleMessageSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleMessageSubmit();
    },
    [handleMessageSubmit]
  );

  const handleSubmitSearchDocumentNumber = useCallback(async () => {
    if (!documentNumber.length) return;
    if (!/^[a-zA-Z\d]+\-[\d]+$/.test(documentNumber)) {
      return setToastMessage({
        severity: 'warning',
        message: ERROR_MESSAGES.INVALID_DOCUMENT_NUMBER,
      });
    }
    if (isLoading) {
      return setToastMessage({
        severity: 'warning',
        message: ERROR_MESSAGES.CANNOT_SUBMIT_WHILE_DOCUMENT_IS_LOADING,
      });
    }

    await onFetchDocument({ documentNumber });
    setDocumentNumber('');
  }, [onFetchDocument, documentNumber, isLoading]);

  const handleSubmitSearchDocumentNumberForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmitSearchDocumentNumber();
    },
    [handleSubmitSearchDocumentNumber]
  );

  return (
    <Box
      marginTop={'8px'}
      sx={{
        borderStyle: 'dashed',
        borderWidth: '3px',
        borderColor: isDragActive ? 'black' : 'transparent',
      }}
      {...rootProps}
    >
      <input {...getInputProps()} multiple />
      <Box
        marginBottom="8px"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.icon,
          borderRadius: '6px',
        })}
      >
        <form onSubmit={handleMessageSubmitForm}>
          <TextField
            value={inputValue}
            variant="outlined"
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your prompt here..."
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton disabled={!inputValue.length} onClick={handleMessageSubmit} edge="end" sx={{ marginRight: '4px' }}>
                  <ArrowForwardIcon />
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
      <Box display="flex" alignItems={'center'}>
        <Box mr={'8px'}>
          <IconButton onClick={onClick}>
            <AttachFile />
          </IconButton>
        </Box>
        <Box width="100%">
          <form onSubmit={handleSubmitSearchDocumentNumberForm}>
            <TextField
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
              placeholder="Enter Document Number"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={
                <Box display="flex" alignItems="center" paddingRight="20px">
                  Document Number
                </Box>
              }
              InputProps={{
                endAdornment: (
                  <IconButton disabled={!documentNumber.length} onClick={handleSubmitSearchDocumentNumber} edge="end" sx={{ marginRight: '4px' }}>
                    <ArrowForwardIcon />
                  </IconButton>
                ),
              }}
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
}

function Settings({
  availableModels,
  setModel,
  model,
  clearTranscript,
  disableClear,
}: {
  availableModels: LLMModel[];
  setModel: (modelId: string) => void;
  model: string;
  clearTranscript: () => undefined;
  disableClear: boolean;
}) {
  return (
    <Box marginBottom={'4px'}>
      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: '32px' }}>AI Doc Chat</Typography>
      </Box>
      <Box display="flex" justifyContent={'flex-end'} marginBottom={'8px'}>
        <Box>
          <Button
            onClick={clearTranscript}
            disabled={disableClear}
            sx={{
              marginRight: '8px',
              fontSize: '16px',
              fontWeight: '400',
              textTransform: 'none',
              marginBottom: '4px',
            }}
          >
            Clear chat
          </Button>
          <Select
            value={model}
            onChange={(event) => setModel(event.target.value)}
            variant="standard"
            disableUnderline
            displayEmpty
            renderValue={() => 'Additional settings'}
            sx={{
              '& .MuiSelect-select': {
                padding: 0,
                fontSize: '16px',
                fontWeight: '400',
                color: 'inherit',
              },
              '& .MuiSelect-root': {
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {availableModels.map(({ model_id, name }) => (
              <MenuItem key={model_id} value={model_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

const filterConsecutiveUserMessages = (messages: any[]) => {
  let result = [];
  let i = 0;

  while (i < messages.length) {
    const current = messages[i];
    const next = messages[i + 1];

    if (current.role === 'user' && next && next.role === 'user') {
      // Skip to the next message
      i++;
    } else {
      result.push(current);
      i++;
    }
  }

  return result;
};
