import { DocFetchParams } from './types';
import { OCRApiDocument } from '../../types/ocr_document';
import { getSfSyncDocDataByDocNumber } from '../../interactors/search-api';
import { getDocPageJob } from '../../interactors/page';
import { getDocumentMetadata, getDocumentText } from '../../interactors/documents';

export const fetchDocument = async ({ documentNumber, documentId }: DocFetchParams): Promise<OCRApiDocument | void> => {
  if (documentNumber) {
    const documentData = await getSfSyncDocDataByDocNumber(documentNumber);
    documentId = documentData.document.id;
  }
  if (!documentId) throw new Error('Document ID or number must be provided');
  return await getDocumentMetadata(documentId);
};

const getDocumentTokenEstimateFromTextLengh = (length: number): number => length / 4;

export const getDocumentTokenEstimate = async (documentId: string): Promise<number> => {
  try {
    return getDocumentTokenEstimateFromTextLengh((await getDocPageJob(documentId)).text_length);
  } catch (e: any) {
    if (e.status === 404) {
      return getDocumentTokenEstimateFromTextLengh((await getDocumentText(documentId)).text.length);
    }
    throw e;
  }
};
