import { APIUrl, getAPIHeaders } from './utils';
import { SearchTypes, SummarizeTextResponse, SummarizeTextRequest } from '../types';
import { safeTrack } from '../provider/Pendo';

export const PromptSandboxBaseUrl = APIUrl.replace('api', 'llm.api').concat('v1');

export const getModels = async () => {
  const response = await fetch(`${PromptSandboxBaseUrl}/models`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const postSummarizeFromText = async (data: SummarizeTextRequest): Promise<SummarizeTextResponse> => {
  const transcriptLength = data.transcript?.length ?? 0;
  safeTrack('Prompt Submitted', {
    model_id: data.model_id,
    document_ids: data.document_ids,
    input_text_length: data.input_text?.length,
    transcript_length: transcriptLength,
    message: data.input_text || data.transcript?.[transcriptLength - 1]?.content,
  });
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-text`, {
    method: 'POST',
    headers: getAPIHeaders(),
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  safeTrack('Response Received', {
    id: result.id,
    model_id: data.model_id,
    input_tokens: result.input_tokens,
    output_tokens: result.output_tokens,
  });
  return result;
};

export async function* getLlmResponseStreaming(data: SummarizeTextRequest) {
  const transcriptLength = data.transcript?.length ?? 0;
  safeTrack('Prompt Submitted', {
    model_id: data.model_id,
    document_ids: data.document_ids,
    input_text_length: data.input_text?.length,
    transcript_length: transcriptLength,
    message: (data.input_text ?? data.transcript?.[transcriptLength - 1]?.content ?? '').substring(0, 200),
  });
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-text?streaming=true`, {
    method: 'POST',
    headers: getAPIHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) throw response;

  if (!response.body) {
    throw new Error('Readable stream is not supported in this environment.');
  }

  const reader = response.body.getReader();
  let decoderBuffer = '';
  let message;

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    decoderBuffer += Array.from(value)
      .map((byte) => String.fromCharCode(byte))
      .join('');
    const parts = decoderBuffer.split('\n\n');
    const cleanedParts = parts.map((part) => part.trim().replace('data: ', ''));
    decoderBuffer = parts.pop() || '';

    for (const part of cleanedParts) {
      if (part.trim()) {
        message = JSON.parse(part.trim());
        if (message?.state !== 'ERROR') {
          yield message;
        } else {
          let errorMessage;
          try {
            errorMessage = JSON.parse(message?.message);
          } catch {
            errorMessage = message?.message;
          } finally {
            throw errorMessage;
          }
        }
      }
    }
  }

  if (message?.state === 'DONE') {
    const { messageId, inputTokens, outputTokens } = message;
    safeTrack('Response Received', {
      id: messageId,
      model_id: data.model_id,
      input_tokens: inputTokens,
      output_tokens: outputTokens,
    });
  }
}

export const postSummarizeFromFile = async (formData: FormData) => {
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-file`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
    body: formData,
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const postSummarizeRag = async (data: Object) => {
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-rag`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const shareToEmail = async (email: string, id: string) => {
  const qs = new URLSearchParams({ email, uid: id });
  const response = await fetch(`${PromptSandboxBaseUrl}/share-to-email?${qs}`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getPDFExportLink = async (id: string) => {
  const qs = new URLSearchParams({ uid: id });
  const response = await fetch(`${PromptSandboxBaseUrl}/export-pdf?${qs}`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getSearchResults = async (model: SearchTypes, searchInput: string, matter_name: string, document_id: string, detected_types: string[], matter_ids: string[] = []) => {
  let query = `search_type=${encodeURIComponent(model)}&q=${encodeURIComponent(searchInput)}`;
  if (matter_name) query += `&matter_mat_name=${encodeURIComponent(matter_name)}`;
  if (document_id) query += `&document_id=${encodeURIComponent(document_id)}`;
  if (detected_types.length > 0) query += `&detected_types=${encodeURIComponent(detected_types.join(','))}`;
  if (matter_ids.length > 0) query += `&matter_ids=${encodeURIComponent(matter_ids.join(','))}`;

  const response = await fetch(`${PromptSandboxBaseUrl}/elastic/search?${query}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });

  try {
    const myJson = await response.json();
    return myJson;
  } catch (e) {
    console.error(e);
    return;
  }
};
