import { APIUrl, getAPIHeaders } from './utils';
import { PageJobResponse } from '../types';

export const PageAPIUrl = APIUrl.replace('api', 'page.api');

export const getDocPageJob = async (documentID: string): Promise<PageJobResponse> => {
  const response = await fetch(`${PageAPIUrl}v1/get-doc-page-job?document_id=${documentID}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return await response.json();
};
